import { useEffect, useState, useRef } from 'react';

const millisecondsToHHMMSS = (ms) => {
  const totalSeconds = parseInt(ms / 1000, 10);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = totalSeconds % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

export default function useGameSessionTimer() {
  const [timer, setTimer] = useState('00:00');
  const startTimestamp = useRef(Date.now());
  const intervalRef = useRef();

  const setTime = () => {
    const timeDiffInMs = Date.now() - startTimestamp.current;
    const formatedTime = millisecondsToHHMMSS(timeDiffInMs);
    setTimer(formatedTime);
  };

  useEffect(() => {
    intervalRef.current = setInterval(setTime, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return timer;
}
