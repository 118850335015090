import { useEffect, useState } from 'react';
import { formatAmount } from 'kmf-i18n';

const getBalanceTextVariant = (balance, highlightPosNegAmount) => {
  if (!highlightPosNegAmount || balance === 0) return 'standard';
  return balance > 0 ? 'positive' : 'negative';
};

const formatBalance = ({ amount, currency, highlightPosNegAmount }) =>
  `${highlightPosNegAmount && amount > 0 ? '+' : ''}${formatAmount(
    amount,
    `currency/${currency}`
  )}`;

export default function useBalanceFormatter({
  amount,
  currency,
  highlightPosNegAmount,
}) {
  const [balanceFormat, setBalanceFormat] = useState({
    balanceString: '',
    textVariant: '',
  });

  useEffect(() => {
    const balanceString = formatBalance({
      amount,
      currency,
      highlightPosNegAmount,
    });
    const textVariant = getBalanceTextVariant(amount, highlightPosNegAmount);
    setBalanceFormat({ balanceString, textVariant });
  }, [amount, currency, highlightPosNegAmount]);

  return balanceFormat;
}
