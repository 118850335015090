import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'kmf-i18n';
import RegulatoryItem from '../RegulatoryItem';
import GameSessionTimer from '../GameSessionTimer';
import Balance from '../Balance';
import useNetPositionNotifier from '../../../../../../hooks/useNetPositionNotifier';
import { RELAXBINGO } from '../../../../../../constants/vendorIds';
import useNetPositionController from '../../../../../../hooks/useNetPositionController';

const highlightPosNegAmount = true;

const RegulatoryItems = ({ gameplaySessionId, vendorId }) => {
  const { showBalance, toggleBalanceVisibility } = useNetPositionController();
  const { amount, currency } = useNetPositionNotifier(gameplaySessionId);
  const { raw } = useTranslation('game-play');

  if (!gameplaySessionId || vendorId === RELAXBINGO) return null;

  return (
    <>
      <RegulatoryItem
        label={raw('gameBar.gameSessionTime')}
        dataTestName="game-session-time"
      >
        <GameSessionTimer />
      </RegulatoryItem>
      <RegulatoryItem
        onClick={() => toggleBalanceVisibility()}
        label={raw('gameBar.gameSessionBalance')}
        dataTestName="game-session-balance"
      >
        <Balance
          amount={amount}
          currency={currency}
          showBalance={showBalance}
          highlightPosNegAmount={highlightPosNegAmount}
        />
      </RegulatoryItem>
    </>
  );
};

RegulatoryItems.propTypes = {
  gameplaySessionId: PropTypes.string,
  vendorId: PropTypes.string,
};

export default RegulatoryItems;
