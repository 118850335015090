import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box } from 'kindred-ui-kit/primitives';
import layout from '@config/layout';

const RegulatoryItem = ({ label, onClick, children, dataTestName }) => (
  <Box
    onClick={onClick}
    data-test-name={dataTestName}
    height="100%"
    borderLeft="xs"
    borderColor="base120"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    pt="xxxs"
    pr="md"
    pl="md"
    cursor={onClick ? 'pointer' : 'auto'}
  >
    {children}
    <Text
      size="xs"
      align="center"
      scheme={layout.textScheme}
      as="span"
      pt="xxxs"
      whiteSpace="nowrap"
      data-test-name="balance-text"
    >
      {label}
    </Text>
  </Box>
);

RegulatoryItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  dataTestName: PropTypes.string,
};

export default RegulatoryItem;
