import React from 'react';
import PropTypes from 'prop-types';
import {
  VerticalStack,
  Icon,
  Text,
  Box,
  HorizontalStack,
} from 'kindred-ui-kit/primitives';
import useBalanceFormatter from '../../../../../hooks/useBalanceFormatter';

const Balance = ({
  amount,
  currency,
  highlightPosNegAmount = false,
  showBalance,
}) => {
  const { balanceString, textVariant } = useBalanceFormatter({
    amount,
    currency,
    highlightPosNegAmount,
  });

  return (
    <Box bg="base120" borderRadius="xl" pl="xxs" pr="xxs">
      <HorizontalStack
        alignItems="center"
        justifyContent="center"
        height="16px"
      >
        {showBalance ? (
          <Text
            as="span"
            scheme="alternative"
            variant={textVariant}
            size="sm"
            whiteSpace="nowrap"
            data-test-name="balance-amount"
          >
            {balanceString}
          </Text>
        ) : (
          <>
            <Box
              width="8px"
              height="8px"
              borderRadius="md"
              mr="xxxs"
              bg="base"
              data-test-name="balance-box-1"
            />
            <Box
              width="8px"
              height="8px"
              borderRadius="md"
              mr="xxxs"
              bg="base"
              data-test-name="balance-box-2"
            />
            <Box
              width="8px"
              height="8px"
              borderRadius="md"
              bg="base"
              data-test-name="balance-box-3"
            />
          </>
        )}
        <VerticalStack justifyContent="center" ml="xxs">
          <Icon
            type={showBalance ? 'hide' : 'show'}
            width="12px"
            height="12px"
            fill="monoY"
            data-test-name="balance-icon"
          />
        </VerticalStack>
      </HorizontalStack>
    </Box>
  );
};

Balance.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  highlightPosNegAmount: PropTypes.bool,
  showBalance: PropTypes.bool,
};

export default Balance;
