import { useEffect, useRef, useState } from 'react';
import { subscribe } from 'xns-client';

const NET_POSITON_NOTIFICATION_XNS_EVENT = 'NetPositionNotification';

export default function useNetPositionNotifier(launherGameplaySessionId) {
  const subscription = useRef();
  const [netPosition, setNetPosition] = useState({
    amount: 0,
    currency: 'GBP',
  });

  const notificationCallBack = (data) => {
    const {
      currency,
      netPosition: netPositionUpdated,
      gameplaySessionId,
    } = data?.body || {};
    if (launherGameplaySessionId === gameplaySessionId)
      setNetPosition({ currency, amount: netPositionUpdated });
  };

  useEffect(() => {
    if (launherGameplaySessionId) {
      subscription.current = subscribe(
        NET_POSITON_NOTIFICATION_XNS_EVENT,
        notificationCallBack
      );
    }

    return () =>
      subscription.current?.unsubscribe && subscription.current.unsubscribe();
  }, [launherGameplaySessionId]);

  return netPosition;
}
