import { useState } from 'react';
import logGameMenuInteraction from '../GamePlay/common/logGameMenuInteraction';

export default function useNetPositionController() {
  const [showBalance, setShowBalance] = useState(true);
  const trackInteraction = () => {
    const eventName = showBalance
      ? 'closed_net_position'
      : 'opened_net_position';
    logGameMenuInteraction(eventName);
  };
  const toggleBalanceVisibility = () => {
    setShowBalance(!showBalance);
    trackInteraction();
  };
  return { showBalance, toggleBalanceVisibility };
}
