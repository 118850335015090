import React from 'react';
import { Text, Icon, HorizontalStack } from 'kindred-ui-kit/primitives';
import useGameSessionTimer from '../../../../../hooks/useGameSessionTimer';

const GameSessionTimer = () => {
  const gameSessionTime = useGameSessionTimer();

  return (
    <HorizontalStack gap="xxs" alignItems="center" height="16px">
      <Icon type="clock" width="12px" height="12px" fill="monoY" />
      <Text as="span" scheme="alternative" size="sm" whiteSpace="nowrap">
        {gameSessionTime}
      </Text>
    </HorizontalStack>
  );
};

export default GameSessionTimer;
